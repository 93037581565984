import { defineStore } from "pinia";
export const useUserStore = defineStore("user", {
  state: () => ({
    user: null,
    isLoggedIn: false
  }),
  actions: {
    clearUser() {
      this.user = null;
      this.isLoggedIn = false;
    },
    setUser(user) {
      this.user = user;
      this.isLoggedIn = true;
    }
  }
});
