<script setup></script>
<template>
  <NuxtLayout name="main-shop-layout">
    <div class="container px-4 xl:px-0">
      <div
        class="w-full mx-auto my-35 flex flex-col-reverse items-center xl:items-stretch xl:grid xl:grid-cols-12"
      >
        <div class="font-sf-pro xl:w-115 mt-8 text-center xl:col-start-1 xl:col-end-5">
          <p class="text-2xl sm:text-4.5xl text-neutral-700">
            Страница не найдена
          </p>
          <p class="mt-5 text-sm sm:text-xl text-neutral-500">
            Возможно, была допущена ошибка в адресе страницы, или она была перемещена
          </p>
        </div>
        <t-error-page class="w-full xl:col-start-5 xl:col-end-13 h-full" />
      </div>
    </div>
  </NuxtLayout>
</template>
