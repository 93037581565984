import { defineStore } from "pinia";
export const useOrganizationStore = defineStore("organization", {
  state: () => ({
    organization: {
      title: "",
      image: {},
      snippet: "",
      description: "",
      phone: "",
      placeId: "",
      roles: []
    }
  }),
  actions: {
    clearOrganization() {
      this.organization = {
        title: "",
        image: {},
        snippet: "",
        description: "",
        phone: "",
        altPhone: "",
        fullname: "",
        placeId: "",
        roles: []
      };
    },
    setOrganization(value) {
      this.organization = {
        ...value,
        title: value.name,
        image: value.photo,
        snippet: value.snippet,
        description: value.description,
        placeId: value.placeId,
        roles: value.roles,
        phone: value.contacts?.phone
      };
    },
    setOrganizationTitle(value) {
      this.organization.title = value;
    },
    setOrganizationImage(value) {
      this.organization.image = value;
    },
    setOrganizationDescription(value) {
      this.organization.description = value;
    },
    setOrganizationSnippet(value) {
      this.organization.snippet = value;
    },
    setOrganizationPlaceId(value) {
      this.organization.placeId = value;
    },
    setOrganizationRoles(value) {
      console.log(value, "value");
      this.organization.roles = value;
    },
    setOrganizationPhone(value) {
      this.organization.phone = value;
    }
  }
});
