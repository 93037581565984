<template>
  <div>
    <NuxtPage />
  </div>
</template>

<script setup>
// import { useUnleashStore } from './stores/unleash.store'
import { Blinkloader } from './utils/blinkloader'
// const unleashStore = useUnleashStore()
const { $get } = useApi()
const {
  public: {
    unleash: { url: unleashUrl, instanceId, appName }
  }
} = useRuntimeConfig()

// const { data: features } = await useAsyncData(
//   'get unleash features',
//   () => $get(`${unleashUrl}/features`, {
//     headers: {
//       'unleash-instanceid': instanceId,
//       'unleash-appname': appName
//     }
//   }),
//   {
//     default: () => []
//   }
// )

// unleashStore.setFeatures(features.value.features)

onMounted(() => {
  Blinkloader.optimize({
    pubkey: '5a084c8fdde49694365a',
    fadeIn: true,
    lazyload: true,
    smartCompression: true,
    responsive: true,
    retina: true,
    webp: true
  })

  {
    ; (function (m, e, t, r, i, k, a) {
      m[i] =
        m[i] ||
        function () {
          ; (m[i].a = m[i].a || []).push(arguments)
        }
      m[i].l = 1 * new Date()
      for (var j = 0; j < document.scripts.length; j++) {
        if (document.scripts[j].src === r) {
          return
        }
      }
      ; (k = e.createElement(t)),
        (a = e.getElementsByTagName(t)[0]),
        (k.async = 1),
        (k.src = r),
        a.parentNode.insertBefore(k, a)
    })(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym')

    ym(95432634, 'init', {
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true,
      ecommerce: 'dataLayer'
    })
  }
})
</script>

<style>
@media (min-width: 425px) {
  .container {
    max-width: 425px !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media (min-width: 640px) {
  .container {
    max-width: 640px !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

/* @media (min-width: 1536px) {
  .container {
    max-width: 1536px !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
} */

img {
  border: 0;
  border-color: transparent;
}
</style>

<style>
lr-img {
  /* --lr-img-dev-mode: 1; */
  --lr-img-pubkey: '5a084c8fdde49694365a';
  --lr-img-breakpoints: '425, 640, 768, 1024, 1200, 1536';
  --lr-img-intersection: 1;
}
</style>
