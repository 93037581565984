import { useRuntimeConfig, useCookie } from "#app";
import { default as Axios } from "axios";
import { COOKIE_KEYS } from "../utils/constants.js";
import { useUserStore } from "../stores/useUserStore.js";
import { useOrganizationStore } from "../stores/useOrganizationStore.js";
export function useApi() {
  const config = useRuntimeConfig();
  const axiosOptions = {
    baseURL: config.public.apiBaseUrl
  };
  const orgStore = useOrganizationStore();
  const usrStore = useUserStore();
  const axiosExtra = {};
  const authToken = useCookie(COOKIE_KEYS.authToken);
  const createAxiosInstance = (axiosOptions2) => {
    const axios2 = Axios.create(axiosOptions2);
    axios2.CancelToken = Axios.CancelToken;
    axios2.isCancel = Axios.isCancel;
    extendAxiosInstance(axios2);
    axios2.interceptors.request.use((config2) => {
      let headers = {
        ...axios2.defaults.headers.common,
        ...config2.headers
      };
      if (authToken.value) {
        headers = { ...headers, authorization: authToken.value };
      }
      if (orgStore.organization?.id) {
        headers = {
          ...headers,
          "x-organization-id": orgStore.organization?.id
        };
      }
      config2.headers = { ...config2.headers, ...headers };
      config2.url = encodeURI(config2.url);
      config2.baseURL = axiosOptions2.baseURL;
      return config2;
    });
    return axios2;
  };
  for (const method of [
    "request",
    "delete",
    "get",
    "head",
    "options",
    "post",
    "put",
    "patch"
  ]) {
    axiosExtra["$" + method] = function() {
      return this[method].apply(this, arguments).then((res) => res && res.data);
    };
  }
  const extendAxiosInstance = (axios2) => {
    for (const key in axiosExtra) {
      axios2[key] = axiosExtra[key].bind(axios2);
    }
  };
  const axios = createAxiosInstance(axiosOptions);
  return {
    ...axios,
    $axios: axios
  };
}
