/* eslint-disable */

export const Blinkloader = (function () {
  function M(a) {
    const b = v.map(function (c) {
      return a.style[c]
    })
    a.addEventListener(
      'transitionend',
      function () {
        a.style.opacity = ''
        b.forEach(function (c, e) {
          a.style[v[e]] = c
        })
      },
      { once: !0 }
    )
    v.forEach(function (c) {
      a.style[c] = 'opacity 1s'
    })
    a.style.opacity = '1'
  }
  function k(a) {
    return void 0 !== a && a !== null
  }
  function N(a) {
    const b = {}
    a.replace(/\s/g, '')
      .split(';')
      .forEach(function (c) {
        c = c.split(':')
        b[n(c[0])] = c[1] || ''
      })
    return b
  }
  function n(a) {
    return a
      .replace(/-(.)/g, function (b) {
        return b.toUpperCase()
      })
      .replace(/-/g, '')
      .replace(/^(.)/, function (b) {
        return b.toLowerCase()
      })
  }
  function O() {}
  function q(a) {
    console.error('Blinkloader: ' + a)
    ;[].slice
      .call(arguments)
      .slice(1)
      .forEach(function (b) {
        console.error(b)
      })
  }
  function l(a) {
    this.node = a.node
    this.K()
    let b
    if ((b = a.node.dataset.blinkSrc)) {
      ;(b = document.createElement('a')), (b.href = a.node.dataset.blinkSrc), (b = b.href)
    }
    b = b || (a.node.dataset.blinkUuid && d.m + '/' + a.node.dataset.blinkUuid + '/')
    let c
    if (!(c = a.a)) {
      c = a.node.dataset.blinkSrc
      const e = a.node.dataset.blinkUuid
      let f = b
      f = f.substring(1 + f.lastIndexOf('/'))
      f = f.split('?')[0]
      f = f.split('#')[0]
      f = f.split('.')[1]
      const m = b.substring(1 + b.lastIndexOf('/')) || void 0
      var g = (g = a.node.clientWidth) && g > 1 ? g : void 0
      var h = (h = a.node.clientHeight) && h > 1 ? h : void 0
      const p = Math.max(
        (document.documentElement || {}).clientHeight || 0,
        window.innerHeight || 0
      )
      const r = a.node.getBoundingClientRect()
      c = {
        src: c,
        H: e,
        url: b,
        h: f,
        filename: m,
        width: g,
        height: h,
        offset: Math.abs(p / 2 - (r.top + r.bottom) / 2),
        L: a.node.dataset.blinkGradient || '',
        j: void 0 !== a.node.dataset.blinkLazyload,
        f: void 0 !== a.node.dataset.blinkProgressive,
        V: void 0 !== a.node.dataset.blinkBackground,
        W: void 0 !== a.node.dataset.blinkImageBlock,
        M: a.node.nodeName === 'IMG',
        b: this.N(a.node.dataset)
      }
    }
    this.a = c
    this.P()
  }
  function y() {
    for (
      let a = document.querySelectorAll('[data-blink-src], [data-blink-uuid]'), b = 0;
      b < a.length;
      ++b
    ) {
      const c = a[b]
      const e = c.dataset.blinkSrc || c.dataset.blinkUuid
      if (void 0 !== e) {
        try {
          let f = void 0
          const m = new l({ node: c })
          typeof Event === 'function'
            ? (f = new Event('Blinkloader.pendingImage'))
            : ((f = document.createEvent('Event')), f.initEvent('Blinkloader.pendingImage', !0, !0))
          f.image = m
          document.dispatchEvent(f)
        } catch (g) {
          !0 !== z[e] && q("couldn't render an image " + e, a[b], g), (z[e] = !0)
        }
      }
    }
    setTimeout(y, 300)
  }
  function P(a) {
    document.addEventListener(
      'Blinkloader.pendingImage',
      function (b) {
        a(b.image)
      },
      !1
    )
    y()
  }
  function Q(a) {
    const b = this
    this.o = a
    this.l = R(a)
    this.w = S(a)
    this.a = this.l.a
    this.node = this.l.node
    d.i && (this.node.style.opacity = '0')
    this.c = function () {
      if (d.g || d.i || d.A || d.f || b.a.f) {
        let c = !1
        ;(d.f || b.a.f) &&
          b.w.C(function () {
            !c && b.w.c()
          })
        const e = function () {
          d.i && M(b.o.node)
          d.g && d.g(b.o.node)
        }
        b.l.D(function (f) {
          f ? b.o.D(e) : (e(), (c = !0))
        })
      } else {
        b.l.c()
      }
    }
  }
  function R(a) {
    const b = JSON.parse(JSON.stringify(a.a))
    let c = b.width || 0
    let e = b.height || 0
    let f = 'lighter'
    d.s && (f = 'smart')
    if (w.B && d.G) {
      c > 0 && (c *= 2)
      e > 0 && (e *= 2)
      if (c > 0 || e > 0) {
        f = 'lightest'
      }
      d.s && (f = 'smart_retina')
    }
    b.b.quality && (f = b.b.quality)
    const m = A.map(function (g) {
      let h = n(g[0])
      if (h === 'quality') {
        return '-/quality/' + f
      }
      if (h === 'resize') {
        if (void 0 !== b.b[h]) {
          return ['-/resize', b.b[h]].filter(Boolean).join('/')
        }
        if (!d.F || !(c > 0 || e > 0)) {
          return '-/preview'
        }
        g = B(b, c, e) ? 5e3 : 3e3
        return c >= e
          ? '-/resize/' + Math.min(Math.max(c, 0), g) + 'x'
          : '-/resize/x' + Math.min(Math.max(e, 0), g)
      }
      if (h === 'format' && B(b, c, e)) {
        return '-/format/jpeg'
      }
      if (void 0 !== b.b[h]) {
        let p = []
        if (h === 'autorotate') {
          return '-/autorotate/yes'
        }
        if (h === 'progressiveJpeg') {
          return '-/progressive/yes'
        }
        h === 'overlayUuid'
          ? (p = ['-/overlay', b.b[n(g[0])], b.b[n(g[1])], b.b[n(g[2])], b.b[n(g[3])]])
          : ((h = '-/' + g[0].replace(/-/g, '_')),
            p.push(h),
            g.forEach(function (r) {
              p.push(b.b[n(r)])
            }))
        return p.filter(Boolean).join('/')
      }
    })
      .filter(Boolean)
      .join('/')
    b.url = a.a.H
      ? [b.url, m].join('') + '/'
      : b.url.indexOf(d.m) === 0
      ? [b.url.slice(0, b.url.lastIndexOf('/')), m, b.filename].join('/')
      : ['https://' + d.host, m, b.url].filter(Boolean).join('/')
    return new l({ node: a.node, a: b })
  }
  function S(a) {
    const b = JSON.parse(JSON.stringify(a.a))
    b.url = a.a.H
      ? [b.url, '-/blur/100/-/progressive/yes/-/resize/65x/'].join('')
      : ['https://' + d.host + '/', '-/blur/100/-/progressive/yes/-/resize/65x/', b.url].join('')
    return new l({ node: a.node, a: b })
  }
  function B(a, b, c) {
    return b > 3e3 || c > 3e3
      ? a.b.format === 'auto'
        ? a.h === 'jpeg' || a.h === 'jpg'
        : a.b.format === 'jpeg'
      : !1
  }
  function C() {
    if (D && u.length !== 0) {
      u.sort(function (b, c) {
        return b.node.offset < c.node.offset ? 1 : -1
      })
      for (let a = 0; a < d.v; a++) {
        ;(u.pop() || { c: O }).c()
      }
    }
  }
  function E() {
    D = !0
    window.removeEventListener('scroll', E)
  }
  function F() {
    try {
      P(function (a) {
        d.valid &&
          a.a.h !== 'svg' &&
          a.a.h !== 'gif' &&
          (a.a.src || '').indexOf('data:') !== 0 &&
          (a = new Q(a))
        let b
        if ((b = d.j || a.a.j)) {
          b = d.I || window.innerHeight || 0
          const c = a.node.getBoundingClientRect()
          b = !!(c.bottom < 0 || c.top - b >= 0)
        }
        b && G ? u.push(a) : a.c()
      })
    } catch (a) {
      q("can't render images", a), setTimeout(F, 3e3)
    }
  }
  var d = {
    valid: !1,
    host: void 0,
    X: void 0,
    A: !0,
    f: !1,
    j: !0,
    v: 5,
    u: 250,
    I: void 0,
    g: void 0,
    i: !1,
    F: !0,
    J: !0,
    G: !0,
    s: !1,
    m: 'https://ucarecdn.com'
  }
  var w = { U: !1, T: !1, protocol: 'https', B: !1, width: 0 }
  var A = [
    ['preview'],
    ['scale-crop', 'scale-crop-position'],
    ['stretch'],
    ['setfill'],
    ['format'],
    ['quality'],
    ['progressive-jpeg'],
    ['srgb'],
    ['max-icc-size'],
    ['enhance'],
    ['sharp'],
    ['blur', 'blur-strength'],
    ['brightness'],
    ['exposure'],
    ['gamma'],
    ['contrast'],
    ['saturation'],
    ['vibrance'],
    ['warmth'],
    ['filter', 'filter-amount'],
    ['grayscale'],
    ['invert'],
    ['autorotate'],
    ['rotate'],
    ['flip'],
    ['mirror'],
    ['overlay-uuid', 'overlay-dimensions', 'overlay-coordinates', 'overlay-opacity'],
    ['resize'],
    ['crop', 'crop-position']
  ]
  const H = Array.prototype.concat.apply([], A)
  l.prototype.K = function () {
    this.node.nodeName !== 'IMG' ||
      this.node.src ||
      (this.node.src =
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=')
  }
  l.prototype.N = function (a) {
    let b = {}
    a.blinkOps && (b = N(a.blinkOps))
    H.forEach(function (c) {
      const e = n(c)
      c = a[n('blink-' + c)]
      void 0 !== c && (b[e] = c)
    })
    d.J && (b.format = b.format || 'auto')
    b.O = b.O || ''
    return b
  }
  l.prototype.C = function (a) {
    const b = new window.Image()
    b.onload = function () {
      a(void 0, !0)
    }
    b.onerror = function (c) {
      a(c, !1)
    }
    b.src = this.a.url
  }
  l.prototype.S = function () {
    this.node.src = this.a.url
  }
  l.prototype.R = function () {
    const a = this
    ;[
      ['background-size', 'cover'],
      ['background-position', 'center'],
      ['background-repeat', 'no-repeat'],
      ['background-image', a.a.L + 'url(' + a.a.url + ')']
    ].forEach(function (b) {
      a.node.style.setProperty(b[0], b[1])
    })
  }
  l.prototype.c = function () {
    this.a.M ? this.S() : this.R()
  }
  l.prototype.D = function (a) {
    const b = this
    b.C(function (c, e) {
      a(c, e)
      !c && b.c()
    })
  }
  l.prototype.P = function () {
    const a = this
    'src uuid lazyload gradient background progressive image-block ops'
      .split(' ')
      .forEach(function (b) {
        a.node.removeAttribute('data-blink-' + b)
      })
    H.forEach(function (b) {
      a.node.removeAttribute('data-blink-' + b)
    })
  }
  var z = {}
  var v = ['transition', 'webkitTransition', 'mozTransition']
  var u = []
  var D = !1
  var G = !1
  return {
    optimize: function (a) {
      try {
        if (a) {
          if (a.host || a.pubkey) {
            d.host = a.host || a.pubkey + '.ucr.io'
            const b = a.fallback
            const c = a.progressive
            const e = a.lazyload
            const f = a.responsive
            const m = a.webp
            const g = a.retina
            const h = a.smartCompression
            const p = a.batchSize
            const r = a.batchInterval
            const I = a.viewHeight
            const J = a.beforeRender
            const K = a.fadeIn
            const L = a.cdnBase
            k(b) && (d.A = b)
            k(c) && (d.f = c)
            k(e) && (d.j = e)
            k(f) && (d.F = f)
            k(m) && (d.J = m)
            k(g) && (d.G = g)
            k(h) && (d.s = h)
            k(p) && (d.v = p)
            k(r) && (d.u = r)
            k(I) && (d.I = I)
            k(J) && (d.g = J)
            k(K) && (d.i = K)
            if (k(L)) {
              let x = document.location.protocol
              x !== 'http:' && (x = 'https:')
              const T = L.replace(/^\/\//, x + '//').replace(/\/+$/, '')
              d.m = T
            }
            d.valid = !0
          } else {
            q('pubkey or host are not specified')
          }
        } else {
          q('missing config')
        }
      } catch (t) {
        q('configuration is invalid', t)
      }
      try {
        ;(w.B = window.matchMedia(
          '(-webkit-min-device-pixel-ratio: 2), (min-device-pixel-ratio: 2), (min-resolution: 192dpi)'
        ).matches),
          (w.width = window.innerWidth)
      } catch (t) {
        q("couldn't populate device parameters", t)
      }
      try {
        window.addEventListener('scroll', E), C(), setInterval(C, d.u), (G = !0)
      } catch (t) {
        q("couldn't initialize lazy loading", t)
      }
      F()
    }
  }
})()
